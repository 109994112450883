<template>
    <div>
        <div class="tile is-ancestor">
            <div class="tile is-parent is-10 space">
                <article class="tile is-child box section-style titles">
                    <p>
                        {{ i18n('Name') }}: {{ score.name }}
                    </p>
                    <p>
                        {{ i18n('Email') }}: {{ score.email }}
                    </p>
                    <p>
                        {{ i18n('Score') }}: {{ score.score }}
                    </p>
                </article>
            </div>
        </div>
        <a class="column is-flex is-full-mobile" @click="goBack">
            <button class="button butt control field">
                {{ i18n('Back') }}
                <fa class="i-icon" icon="arrow-left"/>
            </button>
        </a>
    </div>
</template>

<script>
import { FontAwesomeIcon as Fa } from '@fortawesome/vue-fontawesome';

export default {
    name: 'Show',

    components: { Fa },

    inject: ['i18n', 'http', 'route', 'routerErrorHandler', 'toastr'],

    data: () => ({
        score: {},
    }),

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            this.http
                .get(this.route('score.show', this.$route.params.score))
                .then(({ data }) => {
                    this.score = data.score;
                })
                .catch(this.errorHandler);
        },
        goBack() {
            window.history.go(-1);
        },
    },
};
</script>

<style lang="scss">
@import '../../../sass/enso.scss';

.title-menu {
    font-size: 1.5rem;
    text-align: center;
    font-family: Bahnschrift,serif;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1.2px;
    color: $secondary;
}

.section-style{
    background: $primary;
    padding: 10px;
}

.titles{
    font-family: Bahnschrift,serif;
    text-transform: capitalize;
    font-weight: bold;
    color: $secondary;
}

.titles1{
    text-align: center;
    font-family: Bahnschrift,serif;
    text-transform: capitalize;
    font-weight: bold;
    color: $secondary;
}

.space{
    padding: 3px !important;
    &.btn {
        cursor: pointer;
    }
}

.butt{
    background-color: $orange;
    border-color: transparent;
    color: $secondary !important;
}

.i-icon {
    margin-left: 5px;
}

.textEmail{
    text-transform: none !important;
}

</style>
